import { default as ToastService } from './ToastService.ToastService.ts';
import ToastConfrim from './ToastService.Confirm.vue';
import { markRaw } from 'vue';

/** 
* Function that propmts the user with a confirm dialog
* Returns a Promise that is resolved uppon confirm button click or rejected if the dialog is dismissed
*/
export default function confirm(pOptions: {
    message: string,
    title?: string,
    btnClassOk?: string,
    btnClassCancel?: string,
    btnTextOk?: string,
    btnTextCancel?: string,
    textInputRequired?: boolean,
    initialTextInputValue?: string,
    backdrop?: boolean,
    buttons?: CustomButton[]
}) {
    const toastService = ToastService.getToastService();

    let promsieResolve = (_pOptions: ConfirmOk) => {};
    let promiseReject = (_pOptions: ConfirmCancel) => {};
    const promise = new Promise<ConfirmOk>((res, rej) => {
        promsieResolve = res;
        promiseReject = rej;
    }).finally(() => {
        if (pOptions.backdrop) { toastService.state.backdrop = false; }
    });

    let hasConfirmed = false;
    let userInput: string | null = null;
    let customValue: any = null;

    const cleanedOptions = {
        message: pOptions.message,
        title: pOptions.title,
        btnClassOk: pOptions.btnClassOk,
        btnClassCancel: pOptions.btnClassCancel,
        btnTextOk: pOptions.btnTextOk,
        btnTextCancel: pOptions.btnTextCancel,
        textInputRequired: pOptions.textInputRequired,
        initialTextInputValue: pOptions.initialTextInputValue,
        buttons: pOptions.buttons?.length! > 0 ? pOptions.buttons : undefined,

        resolveConfirm: (inputValue?: string, pCustomValue?: any) => {
            userInput = inputValue ?? null;
            customValue = pCustomValue;
            hasConfirmed = true;
        },
        rejectConfrim: (pReason: any) => {
            customValue = pReason;
        }
    };

    if (pOptions.backdrop) {
        toastService.state.backdrop = true;
    }

    toastService.addCustomToast({
        component: markRaw(ToastConfrim),
        props: cleanedOptions,
        toastClass: '',
        category: 'custom',
        toastOptions: {
            autohide: false,
            delay: 0,
        }
    }, {
        container: 'o365-toasts-confirm',
        onRemove: () => {
            if (hasConfirmed) {
                promsieResolve({
                    CustomAction: customValue ?? undefined,
                    TextInput: userInput ?? undefined
                });
            } else {
                promiseReject({
                    CustomAction: customValue ?? undefined,
                    Canceled: true
                });
            }
        },
        noProgressBar: true
    });

    return promise;
}

type ConfirmCancel = {
    Canceled: true
    CustomAction?: any
};
type ConfirmOk = {
    TextInput?: string,
    CustomAction?: any
};

type CustomButton = {
    class?: string,
    text?: string,
    action: ((pConfirm: () => any, pCancel: () => any) => void)
}

export { confirm };
